<template>
  <!-- <Main></Main> -->
  <router-view />
</template>

<script>
import store from './store';

// import Main from './views/Main'

export default ({
  components: {
    // Main
  },
  setup() {

  }
})
</script>

<style>
@import "./common/css/global.css"
</style>
